<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card title="Edit Location">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">
                        <b-row>
                            <b-col md="12">
                                <b-row>
                                    <b-col md="12">
                                        
                                        <b-form-group label="Project Site" class="required">
                                            
                                            <v-select
                                                v-model="form.site"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                label="site_name"
                                                :options="sites"
                                                placeholder="Select"
                                                @input="getBuilding()"
                                                :clearable="false"
                                            >
                                              <template #selected-option="{ site_name }">
                                                <span v-if="site_name.length < 23">{{site_name}}</span>
                                                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                                              </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col md="12">
                                        
                                        <b-form-group label="Building" class="required">
                                            
                                            <v-select
                                                v-model="form.building"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                label="name"
                                                :options="buildings"
                                                placeholder="Select"
                                                @input="getLevels()"
                                                :clearable="false"
                                            >
                                              <template #selected-option="{ name }">
                                                <span v-if="name.length < 23">{{name}}</span>
                                                <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
                                              </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col md="12">
                                        <b-form-group label="Level" class="required">
                                            
                                            <v-select
                                                v-model="form.level"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="levels"
                                                placeholder="Select"
                                                :clearable="false"
                                            >
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col md="12">
                                        <b-form-group label="Form Types" class="required">
                                            
                                            <v-select
                                                v-model="form.form_type"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="form_types"
                                                multiple
                                                placeholder="Select"
                                                @input="resetLocData()"
                                                :close-on-select=false
                                                :clear-on-select=false
                                                class="multiselect_muliple_options"
                                            >
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                
                                <!-- <b-row>
                                    <b-col md="12">
                                        <b-form-group label="Location Name" class="required">
                                            <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="form.name" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row v-if="req_code2">
                                    <b-col md="12">
                                        <b-form-group label="Code" :class="req_code2 ? 'required': ''">
                                            <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="form.unique_id" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row v-if="req_code2">
                                    <b-col md="12">
                                        <b-form-group label="No. of Area" :class="req_code2 ? 'required': ''">
                                            <b-form-input autocomplete="off" placeholder="1-20" v-model="form.num_area" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col md="12" v-if="checkForm()">
                                        <b-form-group label="Toilet Type" class="required">
                                            <v-select
                                                v-model="form.type"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                :options="location_types"
                                                placeholder="Select"
                                                @input="sortLocType"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row> -->

                                <b-row class="mt-1">
                                    <b-col :cols="req_code2 ? checkForm() ? '5': '12' : '5'">
                                        <h5>Location Name <span class="text-danger">*</span></h5>
                                    </b-col>
                                    <b-col :cols="req_code2 ? '7' : '7'" v-if="checkForm()">
                                        <h5>Toilet Type <span class="text-danger">*</span></h5>
                                    </b-col>
                                </b-row>

                                <b-row class="mt-1">

                                    <b-col :cols="req_code2 ? checkForm() ? '5': '12' : '5'">
                                        <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="form.name" />
                                    </b-col>
                                    <b-col :cols="req_code2 ? '7' : '7'" v-if="checkForm()">
                                        <v-select
                                            v-model="form.type"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            multiple
                                            :options="location_types"
                                            placeholder="Select"
                                            @input="sortLocType"
                                            @option:selected="addLocationType($event)"
                                            @option:deselected="removeLocationType($event)"
                                            :close-on-select=false
                                            :clear-on-select=false
                                            class="multiselect_muliple_options"
                                        />
                                    </b-col>
                                    <b-col cols="12" v-if="req_code2 && form.type.length > 0">
                                        <!-- <legend class="legend_upper_border" style="width:250px">ATTENDANCE SYSTEM</legend>
                                        <div class="legen_inc_div"> -->
                                            <b-row class="mt-2">
                                                <b-col md="3" cols="4">
                                                    <h5>Toilet Name <span class="text-danger">*</span></h5>
                                                </b-col>
                                                <b-col md="3" cols="4">
                                                    <h5>Code <span class="text-danger">*</span></h5>
                                                </b-col>
                                                <b-col md="3" cols="4">
                                                    <h5>No. of Area <span class="text-danger">*</span></h5>
                                                </b-col>
                                            </b-row>
                                            <b-row class="mt-1" v-for="(ltd, ind) in form.location_type_detail" :key="ind">
                                                <b-col md="3" cols="4">{{ltd.name}}</b-col>
                                                <b-col md="3" cols="4">
                                                    <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="ltd.unique_id" :key="ind"/>
                                                </b-col>
                                                <b-col md="3" cols="4">
                                                    <b-form-input autocomplete="off" placeholder="1-20" v-model="ltd.num_area" :key="ind"/>
                                                </b-col>
                                            </b-row>
                                        <!-- </div> -->
                                    </b-col>

                                </b-row>
      
                                <b-row class="mt-2">
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'hand-sanitizer-locations',params:{site_id:form.site._id,id:form.building._id} })">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb,BFormTags
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';
    import AddImage from './AddImage.vue';
    import Bus from "../../../event-bus";

    export default {
        components: {
            BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb,AddImage, BFormTags
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,

                sites:[],
                buildings:[],
                form :{
                    name : '',
                    site : null,
                    building : null,
                    level:null,
                    form_type:[],
                    type:[],
                    unique_id:null,
                    num_area:0,
                    id   : '',
                    location_type_detail:[]
                },
                levels:[],
                form_types:[],
                location_types:['Male','Female','Handicap','Other'],
                req_code:false,
                req_code2: false
            }
        },

        watch: { 
            'form.form_type': function(newVal, oldVal) { // watch it
                if(newVal.includes('Flash Times')){
                    this.req_code2 = true;
                } 
                else if(newVal.includes('Soap Dispenser') || newVal.includes('Floor Traps') || newVal.includes('Air Freshener') || newVal.includes('Toilet Seat Sanitizer')){
                    this.req_code2 = false;
                }
                else {
                    this.req_code2 = false;
                }
            }
        },

        methods : {
            formSubmit(){

                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                        req_code : this.req_code,
                        req_code2:this.req_code2
                    },
                    api : '/api/update-hand-sanitizer-location'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            // this.$router.go(-1);
                            this.$router.push({ name:'hand-sanitizer-locations',params:{site_id:this.form.site._id,id:this.form.building._id} })
                        });
                    }
                });
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        //om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;
                        return this.sites;
                    }
                });
            },
            getBuilding(){

                this.buildings = [];
                this.form.building = null;
                this.levels = [];
                this.form.level = '';

                if (this.form.site != null) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                            role:this.$store.getters.currentUser.role,
                            site:this.form.site ? this.form.site._id : null
                        },
                        api: '/api/all-buildings'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                        } else {
                            this.buildings    = this.$store.getters.getResults.data;
                            return this.buildings;
                        }
                    });
                }
            },
            addRow(){
                this.form.name.push({
                    name : ''
                })
            },

            removeRow(index) {
                
                this.form.name.splice(index, 1);
                
                if (this.form.name.length ==0) {
                    this.form.name.push({
                        name : ''
                    })
                }
                
            },
            getLevels(){
                this.levels = [];
                this.form.level = '';

                if (this.form.building.basement == 'yes') {

                    for (var i = 1; i <= this.form.building.no_of_basements; i++) {
                        this.levels.push('Basement '+i);
                    }

                }

                for (var j = 1; j <= this.form.building.no_of_levels; j++) {
                    this.levels.push('Level '+j);
                }

                return this.levels;
            },
            getDetail(){
                return this.$store.dispatch(POST_API, {
                    data: {
                        id : this.$route.params.id
                    },
                    api: '/api/hand-sanitizer-location-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data = this.$store.getters.getResults.data;
                        
                        this.form.site = data.site;
                        this.getBuilding();
                        this.form.name = data.name;
                        this.form.building = data.building;
                        this.form.form_type = data.form_type;
                        this.form.type = data.location_type;
                        this.form.unique_id = data.unique_id;
                        this.form.num_area = data.num_area;
                        this.form.location_type_detail = data.location_type_detail;
                        /*if (data.graphics != null) {
                            
                            this.form.graphics = {
                                image: data.graphics,
                                name:'',
                                default:'yes'
                            }

                        }else{
                            
                            this.form.graphics = {
                                image: null,
                                name:'',
                                default:'no'
                            }
                        }*/
                        
                        
                        this.getLevels();
                        this.form.id   = data._id;
                        this.form.level = data.level;
                    }
                });
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:{name:'custom-form-settings'},
                text:'Form Settings',
              },
              {
                to: {name:'hand-sanitizer-buildings'}, // hyperlink
                text: 'Buildings', // crumb text
                
              },{
                to: {name:'hand-sanitizer-locations',params:{site_id:this.form.site._id,id:this.form.building._id}}, // hyperlink
                text: 'Locations', // crumb text
                
              },{
                to: null, // hyperlink
                text: 'Edit Location', // crumb text
                
              }];
              return item;
            },
            getForms(){
              return this.$store.dispatch(POST_API, {
                 data:{
                   id: this.$store.getters.currentUser.organization,
                   role: this.$store.getters.currentUser.role,
                 },
                 api: '/api/get-custom-forms-by-role'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                  } else {
                      var data = this.$store.getters.getResults.data.custom_forms;
                      //console.log(this.formsList);
                      if (this.$store.getters.currentUser.role != 'administrator') {
                          data.sort((a, b) => (a.form.title > b.form.title ? 1 : -1))
                      }

                      var form_types = [];

                      if (this.$store.getters.currentUser.role != 'administrator') {
                        form_types = data.map(item => { return item.form.title});
                        form_types.sort((a,b) => (a > b ? 1 : -1 ));

                      }else{
                        form_types = data.map(item => { return item.title});
                      }

                      var arr = ['Conservancy Supervisor Daily Report','Conservancy Supervisor Daily Report on Public Toilets','Daily Supervisor Checklist','Mosquito Prevention Report','Lactation Checklist','Monthly Inspection','Safety Inspection','Toolbox Meeting','Stock Inventory Form'/* , 'Toilet Inspection Checklist' */]

                      var arr1 = []
                      form_types.forEach(function(item){

                         if (arr.indexOf(item) < 0) {
                            arr1.push(item);
                         }
                      })
                      this.form_types = arr1;

                      return this.form_types;
                      
                  }
              });
            },
            checkForm(){
                if (this.form.form_type.indexOf('Flash Times') >=0 || this.form.form_type.indexOf('Soap Dispenser') >=0 || this.form.form_type.indexOf('Floor Traps') >=0 || this.form.form_type.indexOf('Air Freshener') >=0 || this.form.form_type.indexOf('Toilet Seat Sanitizer') >=0) {
                    this.req_code = true;
                }else{
                    this.req_code = false;                   
                }
                return this.req_code;
            },
            resetLocData(){
                if(!this.form.form_type.includes('Flash Times') && !this.form.form_type.includes('Soap Dispenser') && !this.form.form_type.includes('Floor Traps') && !this.form.form_type.includes('Air Freshener') && !this.form.form_type.includes('Toilet Seat Sanitizer')){
                    this.form.type      = [];
                    this.form.unique_id = '';
                    this.form.num_area  = '';

                    this.form.location_type_detail = [];
                } else if(!this.form.form_type.includes('Flash Times')){
                    this.form.unique_id = '';
                    this.form.num_area  = '';
                    this.form.location_type_detail = [];
                }


                if(this.form.form_type.includes('Flash Times') && this.form.location_type_detail.length == 0 && this.form.type.length != 0){

                    for (var i = 0; i < this.form.type.length; i++) {
                        var temp = {
                            name      : this.form.type[i],
                            unique_id : '',
                            num_area  : 0
                        };

                        this.form.location_type_detail.push(temp);
                    }

                    const map = {'Male':1,'Female':2,'Handicap':3,'Other':4};

                    this.form.location_type_detail.sort((a, b) => {
                        return map[a.name] - map[b.name];
                    });

                }
            },
            sortLocType(e){
                const map = {'Male':1,'Female':2,'Handicap':3,'Other':4};

                this.form.type.sort((a, b) => {
                    return map[a] - map[b];
                });
            },
            addLocationType(e){
                var temp = {
                    name      : e[e.length-1],
                    unique_id : '',
                    num_area  : 0
                };
                this.form.location_type_detail.push(temp);

                const map = {'Male':1,'Female':2,'Handicap':3,'Other':4};

                this.form.location_type_detail.sort((a, b) => {
                    return map[a.name] - map[b.name];
                });
            },
            removeLocationType(e){
                var temp = this.form.location_type_detail;
                for(var i = 0; i < temp.length; i++){
                    if(this.form.location_type_detail[i].name == e){
                        this.form.location_type_detail.splice(i,1)
                    }
                }
            },
            sortLocType(e){
                const map = {'Male':1,'Female':2,'Handicap':3,'Other':4};

                this.form.type.sort((a, b) => {
                    return map[a] - map[b];
                });
            }
            
        },
        mounted(){
            Bus.$on('update-image', (data) => {
                this.form.graphics = data.data;
            })
            this.getForms();
            this.allSites();
            this.getDetail();
        }
    }
</script>
